import "./App.css";

const  App = () => {

  const redirectToChefForm = () =>{
    // console.log("workingggg")
    window.location = "https://dashboard.battr.in";
  }


  return (
    <div className="content">
      {/* Section One */}
      <div className="hero-image">
        <div className="container">
          <header style={{ color: "white" }}>
            <img
              className="navbar-logo"
              src="/images/battr.svg"
              alt="battr."
              height="40rem"
              id="navbar-battr-logo"
            />
            <nav>
              <ul className="nav-links">
                <li>
                  <a href="#contactUs">contact us</a>
                </li>
                <li>
                  <a href="#aboutUs">about us</a>
                </li>
              </ul>
            </nav>
          </header>
        </div>

        <div className="row">
          <div className="column">
            <div className="hero-text">
              <p>Partner with Battr and extend your reach</p>
            </div>
            <div className="what-is-battr" id="what-is-battr">
              <p >
                battr is a community platform for home chefs and food
                entrepreneurs, It is a home-chef network that helps you extend
                your reach and boost business.
              </p>
            </div>
            <button
              className="get-started-mobile"
              onClick={redirectToChefForm}
            >
              Get Started
            </button>
          </div>
          <div className="column">
            <div className="form-wrapper">
              <h1 className="form-header">Get Started</h1>
              <form className="get-started-form">
                <input type="text" placeholder="Store Name" />
                <input type="text" placeholder="Store Location" />
                <input type="text" placeholder="Pincode" />
                <input type="text" placeholder="Instagram Handle" />
                <input type="text" placeholder="Business Contact No." />
                <br></br>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <input
                    type="text"
                    placeholder="First Name"
                    style={{ width: "50%" }}
                  />

                  <input
                    type="text"
                    placeholder="Last Name"
                    style={{ width: "50%" }}
                  />
                </div>

                <input type="text" placeholder="Personal Contact No." />
                <p className="terms-and-conditions">
                  By clicking ‘Submit’, you agree to{" "}
                  <mark className="policy-disclaimer">
                    Battr's General Terms And Conditions
                  </mark>{" "}
                  and acknowledge that you have read the{" "}
                  <mark className="policy-disclaimer">Privacy Policy.</mark>
                </p>
                <button className="submit-button">
                  Submit and continue with google
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* Section Two */}
      <div className="section2">
        {/* <div className="row"> */}
        {/* <div className="column"> */}
        <div className="meet-new-customers-textbox">
          <h1>Meet New Customers</h1>
          <br></br>
          <p>
            Thousands of users may be searching for food in your area. by
            partnering with Battr and adding your store to the platform, we can
            help you reach those users
          </p>
          <mark
            style={{
              display: "inline-block",
              marginTop: "12px",

              color: "#EB5424",
              backgroundColor: "white",
              fontSize: "1.3rem",
              cursor: "pointer",
              fontWeight: "500",
            }}
            onClick={() => {
              window.location = "https://dashboard.battr.in";
            }}
          >
            Join us now
          </mark>
        </div>
        {/* </div> */}
        {/* <div className="column"> */}
        <div className="meet-new-customers-imagebox">
          {/* Image applied in CSS */}
        </div>
        {/* </div> */}
        {/* <div className="column"> */}
        <div className="how-battr-works-heading">
          <h1>How Battr works for partners ?</h1>
        </div>
        {/* </div> */}
        {/* </div> */}
        <div className="how-battr-works-for-partners-Boxes-Container">
          <div className="box1 common-box-text">
            <h1>01</h1>
            <b>Create your profile</b>
            <p>
              upload menu and product images and information about your store.
            </p>
          </div>

          <div className="box2 common-box-text">
            <h1>02</h1>
            <b>Get reviews</b>
            <p>get reviews from your past customers.</p>
          </div>
          <div className="box3 common-box-text">
            <h1>03</h1>
            <b>Get orders</b>
            <p>Based on your reviews get orders from customers around you.</p>
          </div>
        </div>
      </div>

      {/* Section 3 */}
      <div className="section3">
        {/* row 1 */}
        <div className="orange-bg">
          <div className="build-your-reputation-imagebox">
            {/* Image applied in CSS */}
          </div>
          <div className="build-your-reputation-textbox">
            <p style={{ color: "white" }}>
              Build your reputation. Attract new customers and maximize your
              revenue from reviews on the biggest platform for homechefs.
            </p>
          </div>
        </div>
        {/* row 2 */}
        <div className="mobile-get-started-container">
          <div className="mobile-image-box"></div>

          <div className="mobile-get-started-textbox">
            <h1>Get started in just 3 steps:</h1>
            <ol>
              <li>
                <p>1. Tell us about your store</p>
              </li>
              <li>
                <p>2. Upload your menu.</p>
              </li>
              <li>
                <p>3. Access store Dashboard and go live.</p>
              </li>
            </ol>
           
            <button className="join-us-button" onClick={redirectToChefForm}
              >
              Join Us
            </button>
          </div>
        </div>
      </div>
      <div className="section4">
        <div className="footer-links-row">
        <a style={{textDecoration:"none"}} href="https://wa.me/+917777071876?text=Hey, I would like to know more about battr.">
        <p>CONTACT US</p>
        </a>
          {/* <p>|what-is-battr</p> */}
          <a style={{textDecoration:"none"}} href="#navbar-battr-logo">
          <p>ABOUT US</p>
          </a>
          
        </div>
        <div className="footer-links-row">
        <a style={{textDecoration:"none"}} href="https://www.battr.in/privacy">
        <p>PRIVACY POLICY</p>
        </a>
         
          {/* <p>|</p> */}
          <p>TERMS & CONDITIONS</p>
        </div>
        <footer>
          <p>&#169; Battr. 2021</p>
          {/* <p>Insta / Twitter / Facebook</p> */}
        </footer>
      </div>
    </div>
  );
}

export default App;
